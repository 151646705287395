:root {
  --primary-color: #1a73e8;
  --secondary-color: #f1f3f4;
  --background-color: #ffffff;
  --text-color: #202124;
  --border-color: #dadce0;
  --hover-background-color: #e8f0fe;
  --modal-background-color: rgba(0, 0, 0, 0.5);
  --gradient-start: #4285f4;
  --gradient-end: #34a853;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
  --transition-duration: 0.3s;
  --font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--secondary-color);
}

.container {
  display: flex;
  height: 100vh;
  position: relative;
  width: 100%; /* Ensure the container takes full width */
}

.sidebar {
  width: 260px;
  padding: 15px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  color: white;
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 2px 0 5px var(--shadow-color);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.close-button, .new-chat-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border-radius: var(--border-radius);
  color: white;
  transition: background-color var(--transition-duration) ease;
}

.close-button:hover, .new-chat-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.tooltip-container, .tooltip-container-2 {
  position: relative;
}

.tooltip-container .tooltip-text, .tooltip-container-2 .tooltip-text-2 {
  visibility: hidden;
  width: auto;
  max-width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 10;
  top: calc(100% + 5px);
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tooltip-container .tooltip-text {
  left: 25%;
}

.tooltip-container-2 .tooltip-text-2 {
  left: 150%;
}

.tooltip-container:hover .tooltip-text, .tooltip-container-2:hover .tooltip-text-2 {
  visibility: visible;
  opacity: 1;
}

.menu-item {
  padding: 8px 0;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color var(--transition-duration) ease;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius);
}

.menu-section {
  margin-top: 20px;
  padding: 10px 0;
  font-weight: bold;
  color: white;
  font-size: 18px;
}

.main-content {
  flex-grow: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: margin-left var(--transition-duration) ease;
  overflow-y: auto;
}

.main-content.with-sidebar {
  margin-left: 0px;
}

.main-content.without-sidebar {
  margin-left: 0;
}

.task-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-wrap: nowrap; /* Ensure the items don't wrap */
}

.task-card {
  padding: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 10px var(--shadow-color);
  cursor: pointer;
  transition: background-color var(--transition-duration) ease, transform var(--transition-duration) ease;
}

.task-card:hover {
  background-color: var(--hover-background-color);
  transform: translateY(-5px);
}

/* Bottom center input container */
.bottom-center {
  position: fixed;
  bottom: 30px; /* Adjust this value to move up or down */
  width: calc(100% - 20px); /* Adjust width if needed */
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  transition: left var(--transition-duration) ease, width var(--transition-duration) ease;
}

.bottom-center.with-sidebar {
  left: calc(50% + 130px); /* Adjust to align correctly with sidebar */
}

.bottom-center.without-sidebar {
  left: 50%;
}

/* Input container styles */
.input-container {
  display: flex;
  align-items: center;
  background-color: var(--hover-background-color);
  /* padding: 10px; */
  border-radius: 50px;
  box-shadow: 0 2px 5px var(--shadow-color);
  width: 80%;
  max-width: 800px;
  height: 50px; /* Ensure consistent height for centering */
  position: relative;
}

.text-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  background: none;
  flex-grow: 1;
  font-size: 16px;
  border-radius: var(--border-radius);
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 14px; /* Adjust for vertical centering */
}

.text-input::placeholder {
  text-align: left;
  line-height: normal; /* Ensure normal line height for centering */
}

.attachment-button, .submit-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color var(--transition-duration) ease;
}

.attachment-button:hover, .submit-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.open-sidebar-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  border-radius: var(--border-radius);
  color: var(--primary-color);
  transition: background-color var(--transition-duration) ease;
}

.open-sidebar-button:hover {
  background-color: rgba(4, 255, 63, 0.837);
}

.top-right {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.profile-button {
  background-color: var(--primary-color);
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 2px 5px var(--shadow-color);
  transition: background-color var(--transition-duration) ease;
}

.profile-button:hover {
  background-color: #00ff1a;
}

.dropdown {
  position: absolute;
  top: 5px;
  right: 0;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 8px 16px var(--shadow-color);
  z-index: 1;
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 180px;
  animation: fadeIn var(--transition-duration) ease-in-out;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: background-color var(--transition-duration) ease;
}

.dropdown-item:hover {
  background-color: var(--hover-background-color);
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--modal-background-color);
}

.modal-content {
  background-color: var(--background-color);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 50%;
  max-width: 600px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 10px var(--shadow-color);
  animation: slideDown var(--transition-duration) ease-in-out;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color var(--transition-duration) ease;
}

.close-modal-button:hover,
.close-modal-button:focus {
  color: black;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chat-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 25px; /* Increase the gap between messages */
  overflow-y: auto;
  flex-grow: 1;
  direction: ltr;
  padding-bottom: 40px;
  margin-left: 0; /* Ensures the chat container starts at the left edge */
}


/* Chat message styles */
.chat-message-container {
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Adjusted to ensure messages don't stretch too wide */
  margin: 0 auto; /* Center align messages */
}

.user-message-container {
  align-items: flex-end;
  margin-left: auto; /* Adjust alignment to the right */
  width: 50%; /* Restrict user message container to 50% width */
  margin-right: 0px; /* Add margin to the right */
}

.bot-message-container {
  align-items: flex-start;
  margin-right: auto; /* Adjust alignment to the left */
  margin-left: 20px; /* Add margin to the left */
}

.chat-message {
  padding: 10px 15px;
  border-radius: var(--border-radius);
  word-wrap: break-word;
}

.user-message {
  background-color: var(--primary-color);
  color: white;
  align-self: flex-end;
}

.bot-message {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  align-self: flex-start;
  max-width: 100%; /* Adjust the width to full */
}


/* Loading animation */
.loading-dots {
  display: inline-block;
}

.loading-dots span {
  animation: blink 1s infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
