.main {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.main-left {
  background-color: #f0f2fa;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-login {
  width: 300px;
}

.main-login {
  font-family: DM Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  margin-bottom: 64px;
}

.input {
  width: 328px;
  height: 56px;
  background: #ffffff;
  border-radius: 16px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding-left: 20px;
}

.error-msg {
  color: #ee6767;
  margin-top: 12px;
}

.link {
  margin-top: 24px;
  color: #58c5c8;
  text-decoration: none;
  margin-bottom: 110px;
}

.link:hover {
  color: #58c5c8;
  text-decoration: none;
}

.login-btn {
  color: rgba(0, 0, 0, 0.4);
  background: #d7dde7;
  border-radius: 16px;
  border: none;
  padding: 17px 24px 18px;
  width: 328px;
  height: 56px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.login-btn.active {
  background: #58c5c8;
  color: #ffffff;
}

.login-btn:disabled {
  background: #d7dde7;
  color: rgba(0, 0, 0, 0.4);
}


.main-right {
  width: 70vw;
  background: linear-gradient(180deg, #abdbd3 0%, #92c4e0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration-login {
  height: 100%;
  width: 100%;
  object-fit: cover;
}